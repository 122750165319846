/*
 * Buttons
 * 
 * Never move a mound of dirt twice.
 *
 * @since 0.0.2
 */

 

.btn {
	display: inline-block;
	font-size: 14px;
	font-family: $font-main;
	font-weight: 700;
	padding: 8px 24px;
	text-transform: uppercase;
	background-color: $btn-bg-default;
	color: $btn-color-default;
	border-radius: $br-4;
	width: auto;

	@include transition-200(all);

	&.--red { background-color: $brand-red; }
	&.--blue { background-color: $brand-blue; }
	&.--green { 
		background-color: $brand-green;

		@include box-shadow-btn(28, 226, 158, 0.75);
	}

	&.--valid {
		background-color: $btn-bg-valid;
	}

	&.--error {
		background-color: $btn-bg-error;
	}

	&.--continue {
		background-color: $btn-bg-continue;

		@include box-shadow-btn(33, 147, 247, 0.75);
	}

	&.--status {
		margin-top: 16px !important;
	}

	&.--back,
	&.--restart {
		background: none;
		padding: 0;
		color: $gray2;
	}

	&.--back 	{ margin-right: 16px; }
	&.--restart { margin-left: 16px; }
}
