/*
 * Animation
 * 
 * A neat little place to store all CSS animations.
 *
 * @since 0.0.2
 */

.anim-hide {
	display: none;
}

.anim-visibility {
	visibility: visible;
	opacity: 1;

	@include transition-200(all); 

	&.--hidden {
		visibility: hidden;
		opacity: 0;
	}
}



/* Fade In  */
@keyframes anim-scale2 {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}



/* Fade In  */
.anim-fade-in	{ animation: anim-fade-in .8s $inandoutqaud; }

@keyframes anim-fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}



/* Scale From Nothing */
@keyframes anim-scale {
	0% {
		opacity: 0;
		transform: scale(0);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}



/* Anim Up */
.anim-up 	{ animation: anim-up .5s $cb-bounce both; }
.anim-up2	{ animation: anim-up .5s $cb-bounce .2s both; }

.anim-up-reverse	{ animation: anim-up-reverse .5s $cb-bounce both; }
.anim-up2-reverse	{ animation: anim-up-reverse .5s $cb-bounce .2s both; }

@keyframes anim-up {
	0% {
		transform: translateY(64px);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes anim-up-reverse {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(96px);
	}
}



/* Anim Down */
.anim-down 	{ animation: anim-down .5s $cb-bounce both; }

@keyframes anim-down {
	0% {
		opacity: 0;
		transform: translateY(0);
	}
	100% {
		opacity: 1;
		transform: translateY(-64px);
	}
}



/* Anim Left */
.anim-left 	{ animation: anim-left .4s $cb-bounce both; }

@keyframes anim-left {
	0% {
		opacity: 0;
		transform: translateX(-64px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}



/* Blur In */
@keyframes blur-in {
	0% {
		filter: blur(64px);
	}
	100% {
		filter: blur(0);
	}
}
