/*
 * Alert
 * 
 * Simple alert styles.
 *
 * @since 0.0.2
 */



.alert {
	position: relative;
	padding: 20px;
	border: 2px solid transparent;
	border-radius: $br-4;

	&.--error {
	    color: $black;
	    background-color: #FCDCE1;
	    border-color: #EDB8B7;
	}

	&.--info {
	    color: $black;
	    background-color: #E0E1E3;
	    border-color: #D5D5DB;
	}

	&.--notice {
	    color: $black;
	    background-color: #ECF7FB;
	    border-color: #BFDFE8;
	}

	&.--success {
	    color: $black;
	    background-color: #EDF6E8;
	    border-color: #B7D3AE;
	}

	&.--warning {
	    color: $black;
	    background-color: #FEFADF;
	    border-color: #FBE8BA;
	}
}
