/*
 * Draggable
 *
 * @since 0.0.4
 */

.draggable-container--is-dragging {
	z-index: 3;
}

.draggable-source--is-dragging {
	border: 4px dashed $gray2;
	box-shadow: none !important;
	background: none !important;

	span.__title,
	span.__txt {
		opacity: 0;
	}
}

.draggable--mirror {
	span.__card {
		border: none;
	}

	span.__title,
	span.__txt {
		opacity: 1;
	}
}
