/*
 * Loader
 * 
 * Styles for loaders. Some loaders are just for the feels.
 *
 * @since 0.0.3
 */

/* Simple OG loader bar with rounded ends. */
.loader-bar {
	height: 8px;
	width: 200px;
	margin: 32px auto 0;
	background: $gray2;
	border-radius: $br-4;
	overflow: hidden;

	.__progress {
		animation: anim-loader-bar 6s $inandoutqaud;
		background-color: $brand-green;
		height: 8px;
	}

	@keyframes anim-loader-bar {
		0% {
			width: 0;
		}
		100% {
			width: 100%;
		}
	}
}
