@font-face {
    font-display: swap;
    font-family: Gotham;
    font-style: normal;
    font-weight: 300;
    src: url(../../fonts/Gotham-Light_Web.woff2) format("woff2"),
        url(../../fonts/Gotham-Light_Web.woff) format("woff");
}
@font-face {
    font-display: swap;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    src: url(../../fonts/Gotham-Book_Web.woff2) format("woff2"),
        url(../../fonts/Gotham-Book_Web.woff) format("woff");
}
@font-face {
    font-display: swap;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    src: url(../../fonts/Gotham-Medium_Web.woff2) format("woff2"),
        url(../../fonts/Gotham-Medium_Web.woff) format("woff");
}
@font-face {
    font-display: swap;
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    src: url(../../fonts/Gotham-Bold_Web.woff2) format("woff2"),
        url(../../fonts/Gotham-Bold_Web.woff) format("woff");
}
