$brand-pf-black:               #1d1d1b;
$brand-pf-primary:				#037fb0;
$brand-pf-gray:     			#6b6b6b;
$brand-pf-secondary: #1f4973;

$font-main-pf: Gotham,Arial,Helvetica,sans-serif;
$font-head-pf: Gotham,Arial,Helvetica,sans-serif;

body.pf {
    font-family: $font-main-pf;

    .alert {
        &.--error {
            color: $brand-pf-black;
        }
        &.--info {
            color: $brand-pf-black;
        }
        &.--notice {
            color: $brand-pf-black;
        }
        &.--success {
            color: $brand-pf-black;
        }
        &.--warning {
            color: $brand-pf-black;
        }
    }

    form.swell-ecom-tool {
        input.--submit {
            font-family: $font-main-pf;
            font-weight: 500;
        }

        label.--radio {
            span {
                font-weight: 500;
            }

            input:checked + span {
                background-color: $brand-pf-primary;
            }
        }

        div.--motivators {
            &[data-order="1"]::before,
            &[data-order="2"]::before,
            &[data-order="3"]::before,
            &[data-order="4"]::before {
                font-family: $font-main-pf;
                font-weight: 500;
            }

            span.__card {
                font-weight: 500;
                color: $brand-pf-primary;
            }
        }

        label.--battery {
            span.__card {
                font-weight: 500;
                color: $brand-pf-primary;
            }

            input:checked + span.__card {
                background-color: $brand-pf-secondary;
                @include box-shadow(107, 107, 107, 0.4);

                span.__title {
                    color: white;
                }
            }
        }

        label.--payment {
            span.__payment-type {
                font-weight: 500;
            }

            input:checked {
                + span.__payment-type {
                    background-color: $brand-pf-primary;
                    border-color: $brand-pf-primary;

                    .__payment-price,
                    .__payment-savings span {
                        color: $brand-pf-primary;
                    }

                    .__payment-savings span {
                        background-color: $brand-pf-primary;
                        border-color: $brand-pf-primary;
                    }
                }
            }
        }

        label.--storage-payment {
            span.__payment-type {
                font-weight: 500;
            }
        }

        label.--lg-payment {
            display: inline-block;
            margin: 0;
            height: 80px;

            h4 {
                margin: auto;
                color: $white;
                text-align: center;
            }

            span.__payment-type {
                display: flex;
                flex-wrap: wrap;
                background-color: $gray2;
                border: 2px solid $gray2;
                padding: 0;
                margin-top: 8px;
                font-weight: 500;
                color: $gray2;
                border-radius: $br-4;
                text-align: center;
                cursor: pointer;
                height: auto;

                @include transition-200(all);
            }

            span.__payment-price,
            span.__payment-savings {
                border-radius: 0;
                width: 100%;
            }

            span.__payment-price {
                background-color: $white;
                padding: 8px;

                span {
                    font-size: 24px;
                }
            }

            span.__payment-savings {
                display: flex;
                flex-direction: column;

                span {
                    text-align: left;
                    padding: 6px;
                    border-left: 2px solid $gray2;
                    border-top: 2px solid $gray2;
                    background-color: $gray;
                }

                span:first-child {
                    border-top: none;
                }
            }

            span.__payment-due-today {
                width: 100%;
                padding: 8px;
                color: $white;
            }

            input {
                display: none;
            }

            input:checked {
                + span.__payment-type {
                    background-color: $brand-pf-primary;
                    border-color: $brand-pf-primary;

                    .__payment-price,
                    .__payment-savings span {
                        color: $brand-pf-primary;
                    }

                    .__payment-savings span {
                        background-color: $brand-pf-gray;
                        border-color: $brand-pf-primary;
                    }
                }
            }
        }

        .__range-card-toggle {
            input.__range-battery-radio:checked + label {
                background-color: $brand-pf-primary;
            }
        }
    }

    .--vpp {
        span.__card {
            color: $brand-pf-primary;
        }
    }

    main {
        &.--edu-topics {
            --swiper-theme-color: #037fb0;
        }

        &.--sunny {
            margin-top: 0px;
            margin-bottom: 16px;

            h1 {
                margin-top: 0;
            }

            h2 {
                font-size: 20px;
                margin-top: 16px;
                font-weight: 400;
            }

            .--hero img {
                margin-top: 64px;
                max-width: 100%;
                max-height: 400px;
            }
        }

        &.--thank-you {
            ul.__thank-you-list li {
                &:before {
                    border-left: 2px solid #037fb0;
                }

                &:after {
                    content: url("../../../img/penfed/bullet.png");
                }
            }

            a {
                color: $brand-pf-primary;
            }
        }
    }

    .progress-bar {
        .__progress {
            background-color: $brand-pf-primary;
        }
    }

    .btn {
        font-weight: 500;
        font-family: $font-main-pf;

        &.--error {
            background-color: $brand-pf-gray;
        }

        &.--continue {
            background-color: $brand-pf-primary;
            box-shadow: none;

            &:hover {
                background-color: $brand-pf-secondary;
            }
        }
    }

    .menu-top__container {
        padding-bottom: 8px;
    }

    .menu-top__logo {
        height: 48px;
    }

    .menu-sunny {
        .__container .grid-row.--contact-row a {
            &:hover,
            &:active {
                background-color: $brand-pf-primary;
            }
        }

        .__link:hover {
            background-color: $brand-pf-primary;
            color: $white;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    legend {
        font-family: $font-head-pf;
		font-weight: 500;
        line-height: 140%;
    }

    h2 {
        &.--max5,
		&.--max6,
		&.--max7 {
			margin: auto;
		}

		&.--max5 { max-width: 500px; }
		&.--max6 { max-width: 600px; }
		&.--max7 { max-width: 700px; }
    }

    p.--note a {
        color: $brand-pf-primary;
    }

    .rangeslider__fill, .rangeslider__handle {
        background: $brand-pf-primary;
    }
}
