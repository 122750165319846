$brand-lg-black: #1d1d1b;
$brand-lg-primary: #a50034;
$brand-lg-gray: #6b6b6b;

$font-main-lg: 'lgsmart', Arial, Helvetica, sans-serif;
$font-head-lg: 'lgsmart', Arial, Helvetica, sans-serif;

body.lg {
    // https://stackoverflow.com/questions/55131944/how-to-remove-blue-background-on-chrome-autocomplete
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    }

    font-family: $font-main-lg;

    .alert {
        &.--error {
            color: $brand-lg-black;
        }

        &.--info {
            color: $brand-lg-black;
        }

        &.--notice {
            color: $brand-lg-black;
        }

        &.--success {
            color: $brand-lg-black;
        }

        &.--warning {
            color: $brand-lg-black;
        }
    }

    form.swell-ecom-tool {
        input.--submit {
            font-family: $font-main-lg;
            font-weight: 500;
        }

        label.--radio {
            span {
                font-weight: 500;
            }

            input:checked+span {
                background-color: $brand-lg-primary;
            }
        }

        div.--motivators {

            &[data-order="1"]::before,
            &[data-order="2"]::before,
            &[data-order="3"]::before,
            &[data-order="4"]::before {
                font-family: $font-main-lg;
                font-weight: 500;
            }

            span.__card {
                font-weight: 500;
                color: $brand-lg-primary;
            }
        }

        label.--battery {
            span.__card {
                font-weight: 500;
                color: $brand-lg-primary;
            }

            input:checked+span.__card {
                background-color: $brand-lg-gray;
                color: $white;
                @include box-shadow(107, 107, 107, 0.4);
            }
        }

        label.--payment {
            span.__payment-type {
                font-weight: 500;
            }

            input:checked {
                +span.__payment-type {
                    background-color: $brand-lg-primary;
                    border-color: $brand-lg-primary;

                    .__payment-price,
                    .__payment-savings span {
                        color: $brand-lg-primary;
                    }

                    .__payment-savings span {
                        background-color: $brand-lg-primary;
                        border-color: $brand-lg-primary;
                    }
                }
            }
        }

        label.--storage-payment {
            span.__payment-type {
                font-weight: 500;
            }
        }

        label.--lg-payment {
            display: inline-block;
            margin: 0;
            height: 80px;

            h4 {
                margin: auto;
                color: $white;
                text-align: center;
            }

            span.__payment-type {
                display: flex;
                flex-wrap: wrap;
                background-color: $gray2;
                border: 2px solid $gray2;
                padding: 0;
                margin-top: 8px;
                font-weight: 500;
                color: $gray2;
                border-radius: $br-4;
                text-align: center;
                cursor: pointer;
                height: auto;

                @include transition-200(all);
            }

            span.__payment-price,
            span.__payment-savings {
                border-radius: 0;
                width: 100%;
            }

            span.__payment-price {
                background-color: $white;
                padding: 8px;

                span {
                    font-size: 24px;
                }
            }

            span.__payment-savings {
                display: flex;
                flex-direction: column;

                span {
                    text-align: left;
                    padding: 6px;
                    border-left: 2px solid $gray2;
                    border-top: 2px solid $gray2;
                    background-color: $gray;
                }

                span:first-child {
                    border-top: none;
                }
            }

            span.__payment-due-today {
                width: 100%;
                padding: 8px;
                color: $white;
            }

            input {
                display: none;
            }

            input:checked {
                +span.__payment-type {
                    background-color: $brand-lg-primary;
                    border-color: $brand-lg-primary;

                    .__payment-price,
                    .__payment-savings span {
                        color: $brand-lg-primary;
                    }

                    .__payment-savings span {
                        background-color: $brand-lg-gray;
                        border-color: $brand-lg-primary;
                    }
                }
            }
        }

        .__range-card-toggle {
            input.__range-battery-radio:checked+label {
                background-color: $brand-lg-primary;
            }
        }
    }

    .--vpp {
        span.__card {
            color: $brand-lg-primary;

            .__content {
                align-items: stretch;
            }

            span.__title,
            span.__txt {
                text-align: center;
            }
        }
    }

    main {
        &.--edu-topics {
            --swiper-theme-color: #a50034;

            strong {
                color: $brand-lg-primary;
            }
        }

        &.--sunny {
            margin-top: 0px;
            margin-bottom: 64px;

            h2 {
                margin-top: 0px;
            }

            .--hero img {
                margin-top: 64px;
                max-width: 100%;
                max-height: 400px;
            }
        }

        &.--thank-you {
            ul.__thank-you-list li {
                &:before {
                    border-left: 2px solid #a50034;
                }

                &:after {
                    content: url("../../../img/bullet-lg.png");
                }
            }

            a {
                color: $brand-lg-primary;
            }
        }
    }

    .progress-bar {
        .__progress {
            background-color: $brand-lg-primary;
        }
    }

    .loader-bar {
        .__progress {
            background-color: $brand-lg-primary;
        }
    }

    .btn {
        font-weight: 500;
        font-family: $font-main-lg;

        &.--error {
            background-color: $brand-lg-gray !important;
            cursor: not-allowed;

            &:hover {
                filter: none !important;
            }
        }

        &.--continue,
        &.--submit {
            background-color: $brand-lg-primary;
            box-shadow: none;

            &:hover {
                filter: brightness(125%);
            }
        }

        &.--secondary {
            background-color: $brand-lg-gray;
            box-shadow: none;

            &:hover {
                filter: brightness(125%);
            }
        }
    }

    .menu-top__container {
        padding-bottom: 8px;
        align-items: center;
        justify-content: space-between;
    }

    .menu-top__logo {
        height: 20px;

        @media screen and (min-width: 500px) {
            height: 36px;
        }
    }

    .menu-top__powered-by {
        p {
            font-size: 0.75rem;
        }

        img {
            height: 16px;
        }

        @media screen and (min-width: 500px) {
            p {
                font-size: 0.9rem;
            }

            img {
                height: 28px;
            }
        }
    }

    .menu-sunny {
        .__container .grid-row.--contact-row a {

            &:hover,
            &:active {
                background-color: $brand-lg-primary;
            }
        }

        .__link:hover {
            background-color: $brand-lg-primary;
            color: $white;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    legend {
        font-family: $font-head-lg;
        font-weight: 500;
    }

    h2 {

        &.--max5,
        &.--max6,
        &.--max7 {
            margin: auto;
        }

        &.--max5 {
            max-width: 500px;
        }

        &.--max6 {
            max-width: 600px;
        }

        &.--max7 {
            max-width: 700px;
        }
    }

    p.--note a {
        color: $brand-lg-primary;
    }

    .rangeslider__fill,
    .rangeslider__handle {
        background: $brand-lg-primary;
    }

    input {
        accent-color: $brand-lg-primary;
    }

    .footer-simple button {
        font-family: $font-main-lg;
    }
}