/*
 * Menus
 * 
 * All menu CSS should live here. 
 *
 * @since 0.0.2
 */

/* Styles for top navagation and logo */
nav.menu-top {
	max-width: $grid-max-width;
	width: 100%;
	margin: 0 auto;
	padding: $grid-padding-xs;
	padding-bottom: 0 !important;

	&.--full {
		max-width: 100%;
	}

	@include transition-200(padding);

	@include respond-min($sm) {
		padding: $grid-padding-sm;
	}
	@include respond-min($md) {
		padding: $grid-padding-md;
	}
	@include respond-min($lg) {
		padding: $grid-padding-lg;
	}
	@include respond-min($xl) {
		padding: $grid-padding-xl;
	}
	&.--simple{

	width: 100%;
	margin: 0 auto;
	padding: $grid-padding-xs 8px;
	padding-bottom: 0px;
max-width: unset;
position: relative;
box-shadow: 0pt 2pt 5pt #939393;
	&.--full {
		max-width: 100%;
	}

	@include transition-200(padding);
}
}


.menu-top__container {
	display: flex;
	padding-bottom: 16px !important;
	
}

.menu-top__powered-by {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	width: 50%;
a{
	display: flex;
}
	p {
		color: #6b6b6b;
		font-style: italic;
	}

	img {
		height: 28px;
		margin-left: 8px;
	}
}

.menu-top__logo,
.menu-top__burger {
	width: 50%;
}

.menu-top__burger {
	text-align: right;
}

.menu-top__logo {
	height: 32px;
	width: auto;
}

/**
 * Menu Form 
 *
 * Menu at the bottom that controls step navigation.
 */
.menu-form {
	margin-top: 64px;
	text-align: center;

	.btn {
		cursor: pointer;
		margin: 0 8px;
		outline: none;
		border: none;
	}
}

/**
 * Menu Sunny
 *
 * Education menu that pops up from sunny in the footer.
 */
.menu-sunny {
	position: absolute;
	bottom: 96px;
	border-radius: 4px;
	max-width: 1366px;
	left: 0;
	width: 100%;
	padding: 0;
	opacity: 0;
	display: none;

	z-index: 50;
	transition: opacity 0.3s, bottom 0.2s $inandoutqaud;

	&.--sunny-active {
		opacity: 1;
		bottom: 160px;
		transition: opacity 0.3s, bottom 0.2s $inandoutqaud;
	}

	.__container {
		position: relative;
		padding: 32px;
		background: $white;
		border-radius: $br-4;
		filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.32));

		&::before {
			content: " ";
			position: absolute;
			left: -50%;
			right: -50%;
			bottom: -14px;
			border-radius: 4px;
			margin: auto;
			width: 32px;
			height: 32px;
			background-color: $white;
			transform: rotate(-45deg) skew(-12deg, -12deg);
			z-index: 3;
		}

		.grid-row {
			z-index: 3;

			&.--contact-row {
				margin-top: 20px;

				[class*="col-"] {
					display: flex;
					flex-direction: row;

					> * {
						margin-top: 10px;
					}
				}

				a {
					background-color: $gray;
					border-radius: $br-4;
					margin: 0.25rem;
					padding: 1rem;
					text-align: center;
					width: 100%;

					&:hover,
					&:active {
						background-color: $brand-green;
						color: $white;
					}

					span {
						display: inline-block;
					}
				}
			}
		}
	}

	.__link {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $white;
		border-radius: $br-4;
		padding: 8px;
		height: 100%;
		font-size: 16px;
		text-align: center;

		@include box-shadow(0, 0, 0, 0.11);

		@include respond-min($md) {
			font-size: 24px;
			padding: 40px;
		}

		&:hover {
			background-color: $brand-blue;
		}
	}
}

.--sunny-active {
	display: block;
}

.--sunny-toggle-active {
	filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.32));
}

.--sunny-anim {
	opacity: 1;
	bottom: 112px;
	transform: translate3d(0, 0, 0);
}



