/**
 * Form
 * 
 * Everything related to form styles.
 *
 * @since 0.0.3
 */

/**
 * Main form styles 
 */
form.swell-ecom-tool {
	fieldset {
		border: none;
		padding: 0;
	}

	input,
	select {
		padding: 16px;
		border-radius: $br-4;
		border: 1px solid $input-border-color;
		width: 100%;
		outline: none;

		@include transition-200(border);

		&::placeholder {
			color: $input-placeholder-color;
		}
		&:focus {
			border-color: $input-border-color-focus;
		}

		&[type="text"]:read-only {
			background-color: lighten($input-placeholder-color, 18%);
			color: darken($input-placeholder-color, 40%);
		}
	}

	.--contact-me-checkbox {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		width: 100% !important;

		input {
			margin: 0;
			padding: 0;
			height: 100%;
			width: 100%;
		}

		label {
			margin: 2px 2px 0;
		}
	}

	input.--submit {
		width: auto;
		border: none;
		display: inline-block;
		font-size: 14px;
		font-family: $font-main;
		font-weight: 700;
		padding: 8px 24px;
		cursor: pointer;
	}

	input[type="range"] {
		padding: 0;
		margin: 0;
	}

	label {
		font-size: 12px;
		text-align: left;
		margin-bottom: 8px;
		margin-left: 8px;
	}

	label.__label {
		display: inline-block;
		width: 100%;
	}

	label.--error {
		color: $input-border-color-error;
	}

	label.--valid {
		color: $input-border-color-valid;
	}

	input.--error,
	select.--error {
		border-color: $input-border-color-error;
	}

	input.--valid,
	select.--valid {
		border-color: $input-border-color-valid;
	}

	.__radio,
	.__range {
		margin-top: 16px;
	}

	.__radio {
		label.--error {
			display: block;
			margin-top: 8px;
		}

		#solar-error {
			text-align: center;
		}
	}

	.__range {
		&.--max {
			max-width: 400px;
			width: 100%;
		}

		&.--center {
			margin: 16px auto 0;
		}

		.__range-contain-value {
			width: 100px;
		}

		.rangeslider {
			flex-grow: 1;
			margin: 0 16px;
		}
	}

	.__range-input {
		width: 72px;
		text-align: center;
	}

	.__range-slider {
		&.--middle {
			margin: 0 16px;
		}

		&.--view-only {
		}
	}

	.__how-large {
		margin-top: 20px;
	}

	.__want-solar {
		margin-top: 20px;
	}
}

/**
 * Radio
 */
form.swell-ecom-tool label.--radio {
	span {
		display: inline-block;
		background-color: $gray2;
		padding: 8px;
		font-weight: 700;
		color: $white;
		border-radius: $br-4;
		width: 64px;
		text-align: center;
		cursor: pointer;

		@include transition-200(background);
	}

	input {
		display: none;
	}

	input:checked + span {
		background-color: $brand-green;
	}
}

/**
 * Battery
 */
form.swell-ecom-tool div.--motivators-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

form.swell-ecom-tool div.--motivators {
	left: 0px;

	@include respond-min($md) {
		left: 40px;
	}

	&[data-order="1"]::before,
	&[data-order="2"]::before,
	&[data-order="3"]::before,
	&[data-order="4"]::before {
		position: absolute;
		top: 50%;
		left: 32px;
		// bottom: -50px;
		transform: translate(-50%, -50%);
		font-size: 32px;
		font-family: $font-main;
		font-weight: 700;
		color: $gray2;
		z-index: 1;

		@include respond-min($md) {
			font-size: 96px;
			left: -40px;
		}
	}

	&[data-order="1"]::before {
		content: "1";
	}
	&[data-order="2"]::before {
		content: "2";
	}
	&[data-order="3"]::before {
		content: "3";
	}
	&[data-order="4"]::before {
		content: "4";
	}

	span.__card {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: $white;
		padding: 16px;
		margin-left: 56px;
		text-align: left;
		font-weight: 700;
		border-radius: $br-4;
		cursor: pointer;
		color: $brand-blue;
		height: 100%;
		z-index: 2;

		@include box-shadow(0, 0, 0, 0.11);
		@include transition-200(background);

		@include respond-min($md) {
			padding: 32px;
			margin-left: unset;
		}

		.__icon {
			height: 56px;
			width: 56px;
			margin-left: -14px;
			margin-right: 14px;
		}

		.__content {
			align-items: center;
			display: flex;
			flex-direction: row;
			flex-grow: 1;

			.__split {
				flex-grow: 1;
			}

			.__img {
				display: none;
				align-items: center;
				justify-content: center;
				width: 100px;

				@include respond-min($md) {
					display: flex;
				}

				img {
					height: 60px;
				}
			}
		}
	}

	span.__title {
		display: block;
		font-size: 24px;
		line-height: 26px;
		margin-bottom: 8px;
		flex: 2;
	}

	span.__txt {
		flex: 1;
		color: $gray3;
	}
}

/**
 * Battery
 */
form.swell-ecom-tool label.--battery {
	span.__card {
		display: flex;
		flex-direction: column;
		background-color: $white;
		text-align: left;
		font-weight: 700;
		border-radius: $br-4;
		cursor: pointer;
		color: $brand-blue;
		opacity: 0.99;
		overflow: hidden;
		height: 100%;

		@include box-shadow(0, 0, 0, 0.11);
		@include transition-200(background);

		.__content {
			padding: 32px;
		}

		img {
			border-top-left-radius: $br-4;
			border-top-right-radius: $br-4;
			filter: grayscale(100%) brightness(50%);
			transition: 0.25s filter linear;
			max-width: 100%;
		}

		&:hover img {
			filter: grayscale(100%) brightness(75%);
		}
	}

	span.__title {
		display: block;
		font-size: 24px;
		line-height: 26px;
		margin-bottom: 8px;
		flex: 2;
	}

	span.__txt {
		flex: 1;
		color: $gray3;
		font-size: initial;
	}

	input {
		display: none;
	}

	input:checked + span.__card {
		background-color: $brand-green;
		color: $white;

		@include box-shadow(28, 226, 158, 0.11);

		img {
			filter: none;
		}
	}

	input:checked + span.__card span.__txt {
		color: $white;
	}
}

form.swell-ecom-tool .payment-buttons {
	display: flex;
	flex-direction: column;

	@include respond-min($md) {
		flex-direction: row;
	}

	button.payment__button {
		background: transparent;
		border: none;
		color: $gray3;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: stretch;
		width: 100%;

		&:not(:last-child) {
			margin-bottom: 24px;

			@include respond-min($md) {
				margin-bottom: 0;
			}
		}

		h4 {
			font-size: 18px;
			text-transform: none;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			width: 100%;

			li {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				font-size: 14px;
				text-align: left;
				text-transform: none;

				span {
					margin-top: 4px;
					margin-right: 4px;

					&.icon-x {
						color: $brand-red;
					}
					&.icon-check {
						color: $brand-green;
					}
				}
			}
		}
	}
}

/**
 * Payment
 */
form.swell-ecom-tool label.--payment {
	display: inline-block;
	margin: 0;
	height: 80px;

	span.__payment-type {
		display: flex;
		flex-wrap: wrap;
		background-color: $gray2;
		border: 2px solid $gray2;
		padding: 0;
		margin-top: 8px;
		font-weight: 700;
		color: $gray2;
		border-radius: $br-4;
		text-align: center;
		cursor: pointer;
		height: 100%;

		@include transition-200(all);
	}

	span.__payment-price,
	span.__payment-savings {
		border-radius: 0;
		width: 100%;
	}

	span.__payment-price {
		background-color: $white;
		padding: 8px;

		span {
			font-size: 24px;
		}
	}

	span.__payment-savings {
		display: flex;
		flex-direction: column;

		span {
			text-align: left;
			padding: 6px;
			border-left: 2px solid $gray2;
			border-top: 2px solid $gray2;
			background-color: $gray;
		}

		span:first-child {
			border-top: none;
		}
	}

	span.__payment-due-today {
		width: 100%;
		padding: 8px;
		color: $white;
	}

	input {
		display: none;
	}

	input:checked {
		+ span.__payment-type {
			background-color: $brand-blue;
			border-color: $brand-blue;
			
			.__payment-price,
			.__payment-savings span {
				color: $brand-blue;
			}

			.__payment-savings span {
				background-color: $brand-light-blue2;
				border-color: $brand-blue;
			}
		}
	}
}

form.swell-ecom-tool label.--storage-payment {
	display: inline-block;
	margin-top: 16px;
	margin-left: 16px;

	span.__payment-type {
		display: flex;
		flex-wrap: wrap;
		background-color: $gray2;
		border: 2px solid $gray2;
		padding: 0;
		margin-top: 8px;
		font-weight: 700;
		color: $gray2;
		border-radius: $br-4;
		width: 200px;
		text-align: center;
		cursor: pointer;

		@include transition-200(all);
	}

	span.__payment-name {
		background-color: $gray;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: stretch;
		width: 100%;
		font-size: 14px;
		padding: 6px;
		border-bottom: 2px solid $gray2;

		@include transition-200(all);
	}

	span.__payment-price,
	span.__payment-savings {
		border-radius: 0;
		width: 100%;
	}

	span.__payment-price {
		background-color: $white;
		padding: 8px;

		span {
			font-size: 24px;
		}
	}

	span.__payment-savings {
		background-color: $gray;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: stretch;
		border-top: 2px solid $gray2;

		span {
			text-align: left;
			padding: 6px;
			background-color: $gray;
		}

		span:first-child {
			border-top: none;
		}
	}

	span.__payment-due-today {
		width: 100%;
		padding: 8px;
		color: $white;
	}

	input {
		display: none;
	}

	input:checked {
		+ span.__payment-type {
			background-color: $brand-blue;
			border-color: $brand-blue;

			.__payment-price,
			.__payment-savings span,
			.__payment-name {
				color: $brand-blue;
			}

			.__payment-savings,
			.__payment-savings span,
			.__payment-name {
				background-color: $brand-light-blue2;
				border-color: $brand-blue;
			}
		}
	}
}

form.swell-ecom-tool .__contact-us {
	display: flex;
	flex-direction: row;
	margin-top: 16px;

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		flex-wrap: 1;
		background-color: $gray;
		border: solid 1px $gray2;
		border-radius: 10px;
		font-size: 1.125rem;
		text-align: center;
		margin: 8px;
		padding: 16px;
		width: 50%;

		&:hover {
			background-color: $brand-green;
			color: $white;
		}

		span {
			display: inline-block;
		}
	}
}

/**
 * Range Card
 */
.form__range-card {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	border: 2px solid $gray2;
	border-radius: $br-4;
	margin-top: 8px;

	&:first-child {
		margin-top: 0;
	}

	img {
		width: 24px;
		margin: 16px;
	}

	input {
		width: 100%;
	}

	p {
		margin-top: 0;
	}

	.__range-card-slider {
		flex: 2;
		margin-right: 16px;

		.__range-slider {
			height: 16px;
		}
	}

	.__range-card-details {
		width: 100%;
		padding: 16px;
		background: $gray;
		text-align: center;

		p {
			width: 100%;
		}
	}

	label.__recommended {
		@include transition-200(all);

		&.--yes {
			color: $gray3;
		}

		&.--no {
			color: $brand-red;
		}
	}
}

form.swell-ecom-tool .__range-card-toggle {
	display: flex;
	flex-direction: column;
	margin-top: 8px;

	@include respond-min($md) {
		flex-direction: row;
	}

	input.__range-battery-radio {
		width: 0;
		height: 0;
		position: absolute;
		left: -9999px;

		&:checked + label {
			background-color: $brand-blue;
			color: $white;
		}
	}

	label {
		background-color: $gray;
		cursor: pointer;
		margin: 0;
		padding: 12px;
		user-select: none;
		width: 100%;

		display: flex;
		justify-content: center;
		align-items: center;

		transition: color 0.25s ease-out, background-color 0.15s ease-out;

		&:first-of-type {
			border-top-left-radius: $br-4;
			border-top-right-radius: $br-4;

			@include respond-min($md) {
				border-top-right-radius: 0;
				border-bottom-left-radius: $br-4;
			}
		}
		&:last-of-type {
			border-bottom-left-radius: $br-4;
			border-bottom-right-radius: $br-4;

			@include respond-min($md) {
				border-top-right-radius: $br-4;
				border-bottom-left-radius: 0;
			}
		}

		img {
			height: 56px;
			width: 48px;
			padding-right: 8px;
		}
	}
}

/**
 * Credit Card
 */
form.swell-ecom-tool .form__credit-card {
	margin-top: 24px;

	.__credit-card-inputs,
	input.submit {
		height: 48px;
	}

	input.submit {
		width: 100%;
		display: inline-block;
		border-radius: 0 0 $br-4 $br-4;
		border: none;
		background: $brand-green;
		color: white;
	}

	.__credit-card-inputs {
		border: 2px solid $gray2;
		border-bottom: none;
		border-radius: $br-4 $br-4 0 0;
		padding: 16px;
		height: auto;

		div {
			flex: 1;
		}

		div:first-child {
			flex: 3;
		}

		div:last-child {
			flex: 0.5;
			width: 56px;
		}

		#month,
		#cvc {
			text-align: right;
		}
	}

	input {
		padding: 0;
		border: none;
	}
}

/**
 * General Information
 */
form.swell-ecom-tool .__info > {
	div {
		width: 100%;
		padding: 24px 8px 0 8px;
	}

	@include respond-min($sm) {
		div {
			width: 50%;
		}
	}
}

/**
 * Address
 */
form.swell-ecom-tool .__address {
	div {
		padding: 16px 8px 0 8px;
	}
	div.--address-row {
		padding: 0;
		flex-grow: 1;
	}
	.__street {
		flex: 2;
		flex-grow: 2;
	}
	.__apt {
		flex: 1;
		flex-grow: 1;
	}
	.__address-problem {
		background-color: lighten($brand-yellow, 20%);
		border-color: darken($brand-yellow, 25%);
		padding: 10px;
		margin: 6px;
		text-decoration: underline;
	}
	.__city {
		flex: 3;
		flex-grow: 3;
	}
	.__state {
		flex: 1;
		flex-grow: 1;
	}
	.__zip {
		flex: 2;
		flex-grow: 2;
	}
}

.--vpp {
    span.__card {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $white;
        padding: 16px;
        text-align: left;
        font-weight: 500;
        border-radius: $br-4;
        color: $brand-blue;
        height: 100%;
        z-index: 2;

        @include respond-min($md) {
            padding: 32px;
        }
		
        .__icon {
            height: 56px;
            width: 56px;
            margin-left: -14px;
            margin-right: 14px;
        }

        .__content {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            .__split {
                flex-grow: 1;
            }

            .__img {
                display: none;
                align-items: center;
                justify-content: center;
                width: 100px;

                @include respond-min($md) {
                    display: flex;
                }

                img {
                    height: 60px;
                }
            }
        }
    }

    span.__title {
        display: block;
        font-size: 16px;
        line-height: 20px;
		margin-bottom: 8px;
		text-align: left;
    }

    span.__txt {
        flex: 1;
        color: $gray3;
		font-size: 14px;
		font-weight: 400;
		text-align: left;
    }
}
