/*
 * Footer
 * 
 * Styles for the footer of the page.
 *
 * @since 0.0.3
 */

.footer {
  position: relative;
  max-width: $grid-max-width;
  width: 100%;
  margin: 0 auto;
  padding: $grid-padding-xs;
  padding-top: 0 !important;

  &.--full {
    max-width: 100%;
  }

  @include transition-200(padding);

  @include respond-min($sm) {
    padding: $grid-padding-sm;
  }

  @include respond-min($md) {
    padding: $grid-padding-md;
  }

  @include respond-min($lg) {
    padding: $grid-padding-lg;
  }

  @include respond-min($xl) {
    padding: $grid-padding-xl;
  }

  .footer__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid $gray2;
    padding-top: 48px;

    p {
      font-size: 12px;
      color: $gray2;
      margin: 0;
      text-align: center;
    }

    @include respond-min($md) {
      justify-content: space-between;
      align-items: unset;
      flex-direction: unset;
      padding-top: 12px;
    }
  }

  .footer__sunny {
    position: relative;
    width: 64px;
    height: 64px;
    margin: auto;
    margin-bottom: -32px;
    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      border-radius: 50%;
    }

    &::before {
      content: '\e905';
      opacity: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: swell-icons;
      font-size: 24px;
      background-color: white;
      z-index: 2;
      width: 24px;
      height: 24px;

      @include transition-200(opacity);
    }

    &::after {
      content: '';
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0);
      width: 64px;
      height: 64px;
      z-index: 1;

      @include transition-200(background);
    }

    &:hover {
      transform: scale(1.1);
    }

    &.--sunny-toggle-active {
      &::after {
        background-color: rgba(0, 0, 0, 0.25);
      }

      &::before {
        opacity: 1;
      }
    }

    img {
      width: 64px;
      height: auto;
    }
  }
}

.Popover-body {
  background-color: $gray;
  padding: 2rem;
}

/*
 * Footer
 * 
 * Styles for the footer of the page.
 *
 * @since 0.0.3
 */

.footer-simple {
  position: relative;
  padding: 0px 8px;
  width: 100%;
  margin: 0 auto;

  box-shadow: inset 0pt 4pt 5pt #d9dadb;

  @media only screen and (max-width: 800px) {
    text-align: center;
  }

  .leftText {
    line-height: 18px;
    margin-top: 16px !important;
    white-space: nowrap;

    @media only screen and (min-width: 1048px) {
      white-space: nowrap !important;
    }

    @media only screen and (min-width: 800px) {
      text-indent: -0.8em;
      padding-left: 1em;
      text-align: left !important;
      margin-top: 0px !important;

      white-space: pre-line;
    }
  }

  &.--full {
    max-width: 100%;
  }

  button.--toggle-button {
    transform: translateY(10px) translateX(-50%);
    margin-left: 50%;
    margin-top: 6px;
    background-color: white;
    text-transform: capitalize;
    color: #1d1d1b;
    border: none;
    cursor: pointer;
    mark {
      color: #a50034;
      background-color: white;

      padding: 0px 4px;
      text-transform: capitalize;
    }
    @media only screen and (max-width: 800px) {
      transform: translateY(10px);
      margin-left: unset;
    }
  }

  .footer-modal {
    display: none;
    position: absolute;
    background: white;
    top: -7rem;
    border: 1px solid lightgray;
    box-shadow: 0px 3px 4px 0px #7c7c7cf2;
    border-radius: 8px;

    left: 50%;
    transform: translateX(-50%);
    width: 99%;

    @include respond-min($md) {
      width: 100%;
      max-width: 480px;
    }

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0px 8px;
      color: #6b6b6b;
      cursor: pointer;
      padding: 22px 16px;
      flex: 1;

      span {
        color: inherit;
        margin-top: 8px;
        text-align: center;
      }
    }

    a:first-child {
      border-right: 1px solid #6b6b6b22;
    }

    a:hover {
      color: #a50034;
    }
  }

  .footer-modal.open {
    display: flex;
  }

  .footer__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid $gray2;

    p {
      color: #1d1d1b;
      margin: 0;
      text-align: center;
      transform: unset;
      margin-top: 4px;
      font-size: 12px;

      @media only screen and (max-width: 420px) {
        font-size: 10px;
      }
      @include respond-min($md) {
        margin-top: 0;
      }
    }

    @media only screen and (min-width: 800px) {
      justify-content: space-between;
      align-items: unset;
      flex-direction: unset;
    }
  }

  .Popover-body {
    background-color: $gray;
    padding: 2rem;
  }

  .footer__sunny {
    position: relative;
    width: 64px;
    height: 64px;
    margin: auto;
    margin-bottom: -32px;

    &.--click-action {
      cursor: pointer;
    }

    &::before,
    &::after {
      position: absolute;
      border-radius: 50%;
    }

    &::before {
      content: '\e905';
      opacity: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: swell-icons;
      font-size: 24px;
      background-color: white;
      z-index: 2;
      width: 24px;
      height: 24px;

      @include transition-200(opacity);
    }

    &::after {
      content: '';
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0);
      width: 64px;
      height: 64px;
      z-index: 1;

      @include transition-200(background);
    }

    &.--click-action:hover {
      transform: scale(1.1);
    }

    &.--sunny-toggle-active {
      &::after {
        background-color: rgba(0, 0, 0, 0.25);
      }

      &::before {
        opacity: 1;
      }
    }

    img {
      width: 64px;
      height: auto;
    }
  }
}
