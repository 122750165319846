/*
 * Variables
 * 
 * Never move a mound of dirt twice.
 *
 * @since 0.0.2
 */



/* Hex */
$black:						#000000;
$white: 					#FFFFFF;
$gray:						#F2F2F2;
$gray2:						#CCCCCC;
$gray3:						#3e3e3e;
$brand-blue:				#2193F7;
$brand-light-blue:			#A3DBFC;
$brand-light-blue2:			#d4e8f9;
$brand-red:					#EF5951;
$brand-green:				#1CE29E;
$brand-yellow:				#FFF275;
$brand-gray:				#D1DDD8;

/* RGBA */
$rgba-black:		rgba(0, 0, 0, 0.75);
$rgba-black-shadow: 0, 0, 0;
$rgba-green-shadow: 28, 226, 158;



/* Body */
$body-bg-color: $white;



/* Boarder Radius */
$br-4 : 4px;



/* Typography */
$font-main: Quicksand, helvetica, sans-serif;
$font-head: Quicksand, helvetica, sans-serif;
$font-copy: Quicksand, helvetica, sans-serif;
$font-main-color: $black;
$font-selection-bg: $brand-yellow;
$font-selection-txt: $black;



/* Buttons */
$btn-bg-default: $gray2;
$btn-bg-valid: $brand-green;
$btn-bg-error: $brand-red;
$btn-bg-continue: $brand-blue;
$btn-color-default: $white;



/* Forms and Inputs */
$input-placeholder-color: $gray2;
$input-border-color: $gray2;
$input-border-color-focus: $brand-blue;
$input-border-color-valid: $brand-green;
$input-border-color-error: $brand-red;



/* Cubic Bezier */
$inandoutqaud: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$cb-bounce: cubic-bezier(0.855, -0.600, 0.135, 1.650);



/* Grid */

// Max width of .grid-container
$grid-max-width: 1366px;

// Padding of .grid-container, these values are also used on header and footer.
$grid-padding-xs: 24px;
$grid-padding-sm: 32px;
$grid-padding-md: 40px;
$grid-padding-lg: 56px;
$grid-padding-xl: 64px;



/* Responsive Breakpoints */

// Default
$xl:	1366px;
$lg: 	1024px;
$md: 	768px;
$sm: 	425px;
$xs: 	375px;

// Used in special cases
$container: 1366px;
$menu-mobile: 900px;



/* Z Index */
$z-menus: 1000;
