@font-face {
    font-family:'lgsmart';
    font-weight:300;
    font-style:normal;
    src:url('../../fonts/LGSmartLight-Light.eot?#iefix') format('embedded-opentype'), 
        url('../../fonts/LGSmartLight-Light.woff') format('woff');
}
@font-face {
    font-family:'lgsmart';
    font-weight:400;
    font-style:normal;
    src:url('../../fonts/LGSmart.eot?#iefix') format('embedded-opentype'), 
        url('../../fonts/LGSmart.woff') format('woff');
}
  
@font-face {
    font-family:'lgsmart';
    font-weight:500;
    font-style:normal;
    src:url('../../fonts/LGSmartLight-SemiBold.eot?#iefix') format('embedded-opentype'), 
        url('../../fonts/LGSmartLight-SemiBold.woff') format('woff');
}

@font-face {
    font-family:'lgsmart';
    font-weight:700;
    font-style:normal;
    src:url('../../fonts/LGSmart-Bold.eot?#iefix') format('embedded-opentype'), 
        url('../../fonts/LGSmart-Bold.woff') format('woff');
}