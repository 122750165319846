.cls-1 {
    fill: #fff;
}

.cls-2 {
    fill: #b1dbb8;
}

.cls-3 {
    fill: #e0e0e0;
}

.cls-4,
.cls-57,
.cls-58,
.cls-61 {
    fill: none;
}

.cls-5 {
    fill: #cccccb;
}

.cls-6 {
    fill: #7ec247;
    opacity: 0.39;
}

.cls-7 {
    fill: #f6f6f6;
}

.cls-8 {
    fill: #bddfba;
}

.cls-9 {
    fill: #6abd45;
    opacity: 0.36;
}

.cls-15 {
    fill-rule: evenodd;
}

.cls-12 {
    fill: #ababab;
}

.cls-15 {
    fill: #f6f7f8;
}

.cls-17 {
    fill: #f4f4f5;
}

.cls-20 {
    fill: #4f4f4f;
}

.cls-36 {
    fill: #b7b7b7;
}

.cls-38 {
    fill: #54a4db;
    opacity: 0.33;
}

.cls-45 {
    fill: #aed8a6;
}

.cls-57,
.cls-58 {
    stroke: #4f4f4f;
}

.cls-57 {
    stroke-miterlimit: 10;
}

.cls-58,
.cls-61 {
    stroke-linecap: round;
    stroke-linejoin: round;
}

.cls-61 {
    stroke: #9b9a9a;
}
