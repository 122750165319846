.--battery-main {
    .grid-row {
        label {
            margin-bottom: 16px;
            margin-left: 0;
        }
    }
}


.--battery-main.--lg {
    form.swell-ecom-tool label.--battery span.__card {
        display: flex;
        flex-direction: row;
        height: 400px;

        @media screen and (max-width: 680px) {
            flex-direction: column;
            height: auto;
        }

        span.__title {
            font-size: 20px;
        }

        span.__txt {
            font-size: 14px;
            font-weight: normal;
        }

        img {
            object-fit: cover;
            width: 220px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            @media screen and (max-width: 680px) {
                width: 100%;
                height: 220px;
            }
        }

        .--appliances-list {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            height: 100%;

            label {
                display: flex;
                flex-direction: row;
                width: 160px;
                align-items: center;
                justify-content: flex-start;
            }
            input[type="checkbox"] {
                display: initial;
                width: auto;
                accent-color: #a50034;
            }
        }
    }
}