/*
 * Base
 * 
 * Lets make some default decisions.
 *
 * @since 0.0.2
 */


html,
body,
:root {
	font-family: $font-main;
	color: $font-main-color;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: grayscale;
}

html, 
body {
	margin: 0;
	background-color: $body-bg-color;
}

html { 
	box-sizing: border-box;
	scroll-behavior: smooth;

	// Disable smooth scrolling when users have prefers-reduced-motion enabled.
	@include reduced-motion(reduce) {
		scroll-behavior: auto;
	}
}

*, 
*:before, 
*:after { 
	box-sizing: inherit;
}



.swell-et-wrap {
	display: flex;
	flex-direction: column;
	height: 100vh;

	main { flex: 2; }
}
