/*
 * Grid
 * 
 * A simple grid system using flex, based on Bootstrap.
 *
 * @since 0.0.2
 */

/* Container */
.grid-container {
	position: relative;
	max-width: $grid-max-width;
	width: 100%;
	margin: 0 auto;
	padding: $grid-padding-xs;

	&.--full {
		max-width: 100%;
	}

	@include transition-200(padding);

	@include respond-min($sm) {
		padding: $grid-padding-sm;
	}
	@include respond-min($md) {
		padding: $grid-padding-md;
	}
	@include respond-min($lg) {
		padding: $grid-padding-lg;
	}
	@include respond-min($xl) {
		padding: $grid-padding-xl;
	}
}

/* Row */
.grid-row {
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 -12px;

	&.--reverse {
		flex-wrap: wrap-reverse;
	}
	&.--row-reverse {
		flex-direction: row-reverse;
	}
	&.--justify-center {
		justify-content: center;
	}
	&.--justify-right {
		justify-content: right;
	}
	&.--justify-space {
		justify-content: space-between;
	}
	&.--align-center {
		align-items: center;
	}
	&.--align-bottom {
		align-items: flex-end;
	}
	&.--wrap-no-wrap {
		flex-wrap: nowrap;
	}

	@include respond-min($md) {
		&.--justify-center-md {
			justify-content: center;
		}
	}
}

/* Margins */
.--grid-mtop {
	margin-top: $grid-padding-xs;

	@include respond-min($sm) {
		margin-top: $grid-padding-sm;
	}
	@include respond-min($md) {
		margin-top: $grid-padding-md;
	}
	@include respond-min($lg) {
		margin-top: $grid-padding-lg;
	}
	@include respond-min($xl) {
		margin-top: $grid-padding-xl;
	}
}

/*
 * Columns
 */
[class*="col-"] {
	position: relative;
	margin-bottom: 16px;
	padding: 0 12px;

	/* Override for slider.js */
	box-sizing: border-box;
}

/* Modifies Columns */
.col-no-margin {
	margin-bottom: 0;
}

@include respond-min($sm) {
	.col-no-margin-sm {
		margin-bottom: 0;
	}
}

@include respond-min($md) {
	.col-no-margin-md {
		margin-bottom: 0;
	}
}

@include respond-min($lg) {
	.col-no-margin-lg {
		margin-bottom: 0;
	}
}

@include respond-min($xl) {
	.col-no-margin-xl {
		margin-bottom: 0;
	}
}

/* Content Columns
 * 
 * Do not put this on col-**-**. Use it as a child inside of col-**-** 
 */
.col-content {
	column-count: 1;
	column-gap: 32px;
	column-width: 100%;
	padding: 0;

	@include respond-min($md) {
		column-count: 2;
		column-gap: 32px;
		column-width: 50%;
	}
}

/* XS Default for mobile first */
.col-xs-12 {
	width: 100%;
}
.col-xs-11 {
	width: 91.67%;
}
.col-xs-10 {
	width: 83.33%;
}
.col-xs-9 {
	width: 75%;
}
.col-xs-8 {
	width: 66.67%;
}
.col-xs-7 {
	width: 58.33%;
}
.col-xs-6 {
	width: 50%;
}
.col-xs-5 {
	width: 41.67%;
}
.col-xs-4 {
	width: 33.33%;
}
.col-xs-3 {
	width: 25%;
}
.col-xs-2 {
	width: 16.67%;
}
.col-xs-1 {
	width: 8.33%;
}

@include respond-min($sm) {
	.col-sm-12 {
		width: 100%;
	}
	.col-sm-11 {
		width: 91.67%;
	}
	.col-sm-10 {
		width: 83.33%;
	}
	.col-sm-9 {
		width: 75%;
	}
	.col-sm-8 {
		width: 66.67%;
	}
	.col-sm-7 {
		width: 58.33%;
	}
	.col-sm-6 {
		width: 50%;
	}
	.col-sm-5 {
		width: 41.67%;
	}
	.col-sm-4 {
		width: 33.33%;
	}
	.col-sm-3 {
		width: 25%;
	}
	.col-sm-2 {
		width: 16.67%;
	}
	.col-sm-1 {
		width: 8.33%;
	}
}

@include respond-min($md) {
	.col-md-12 {
		width: 100%;
	}
	.col-md-11 {
		width: 91.67%;
	}
	.col-md-10 {
		width: 83.33%;
	}
	.col-md-9 {
		width: 75%;
	}
	.col-md-8 {
		width: 66.67%;
	}
	.col-md-7 {
		width: 58.33%;
	}
	.col-md-6 {
		width: 50%;
	}
	.col-md-5 {
		width: 41.67%;
	}
	.col-md-4 {
		width: 33.33%;
	}
	.col-md-3 {
		width: 25%;
	}
	.col-md-2 {
		width: 16.67%;
	}
	.col-md-1 {
		width: 8.33%;
	}
}

@include respond-min($lg) {
	.col-lg-12 {
		width: 100%;
	}
	.col-lg-11 {
		width: 91.67%;
	}
	.col-lg-10 {
		width: 83.33%;
	}
	.col-lg-9 {
		width: 75%;
	}
	.col-lg-8 {
		width: 66.67%;
	}
	.col-lg-7 {
		width: 58.33%;
	}
	.col-lg-6 {
		width: 50%;
	}
	.col-lg-5 {
		width: 41.67%;
	}
	.col-lg-4 {
		width: 33.33%;
	}
	.col-lg-3 {
		width: 25%;
	}
	.col-lg-2 {
		width: 16.67%;
	}
	.col-lg-1 {
		width: 8.33%;
	}
}

@include respond-min($xl) {
	.col-xl-12 {
		width: 100%;
	}
	.col-xl-11 {
		width: 91.67%;
	}
	.col-xl-10 {
		width: 83.33%;
	}
	.col-xl-9 {
		width: 75%;
	}
	.col-xl-8 {
		width: 66.67%;
	}
	.col-xl-7 {
		width: 58.33%;
	}
	.col-xl-6 {
		width: 50%;
	}
	.col-xl-5 {
		width: 41.67%;
	}
	.col-xl-4 {
		width: 33.33%;
	}
	.col-xl-3 {
		width: 25%;
	}
	.col-xl-2 {
		width: 16.67%;
	}
	.col-xl-1 {
		width: 8.33%;
	}
}
