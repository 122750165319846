.progress-bar {
    height: 2px;
    width: 100%;
    margin: 0 auto;
    background: $gray2;
    overflow: hidden;
&.--simple{
    display: none;
}
    .__progress {
        background-color: $brand-green;
        height: 2px;
        -webkit-transition: width 1s ease-in-out;
        -moz-transition: width 1s ease-in-out;
        -o-transition: width 1s ease-in-out;
        transition: width 1s ease-in-out;
    }
}
