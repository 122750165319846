.swiper-slide {
  .grid-row {
    padding: 40px;
  }

  @include respond-min($sm) {
    .grid-row {
      padding: 60px;
    }
  }

  img {
    width: 100%;
  }
}
