/*
 * Main
 * 
 * A place for style of elements inside the main tag, using modifiers.
 *
 * @since 0.0.3
 */

/*
 * Main
 */
main.--main {
  min-height: 70vh;

  h1 {
    text-align: center;
  }
}

// Set everything to text-align center with in grid-container
main.--center {
  text-align: center;
}

/*
 * Address
 */
main.--address {
  padding-bottom: 100px;

  img {
    width: 100%;
  }

  address p {
    font-size: 12px;
    line-height: 16px;
  }

  .swell-ecom-tool {
    width: 100%;
    max-width: 800px;
    margin: auto;
  }

  &.--decline {
    img.sunny {
      display: block;
      max-width: 184px;
      margin: auto;
    }

    .swell-ecom-tool {
      max-width: 600px;
    }
  }
}

/*
 * Calculating
 */
main.--calculating {
  img {
    margin-top: 64px;
  }

  .grid-container {
    max-width: 800px;
  }
}

/*
 * Confirmation
 */
main.--confirmation {
  .grid-container {
    max-width: 800px;
  }
}

/*
 * Education
 */
main.--edu-topics {
  h1 {
    text-align: left;
  }
}

/*
 * General Information
 */
main.--info {
  .grid-container {
    max-width: 600px;
  }
}

/*
 * Own Solar
 */
main.--own-solar {
}

/*
 * Existing Solar
 */
main.--existing-solar {
  .grid-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
  }

  .--call-button {
    background-color: $gray;
    display: block;
    margin-top: 40px;
    padding: 10px;
    text-align: center;
    width: 300px;

    &:hover {
      background-color: $brand-green;
      color: $white;
    }
  }
}

/*
 * Sunny
 *
 * Steps that have sunny as the header Avatar need a little extra space on the H1.
 */
main.--sunny {
  h1 {
    margin-top: 64px;
  }

  svg {
    max-width: 156px;
    // max-width: 300px;
  }
}

/*
 * Recommendation
 */
main.--recommendation {
  img.recommendation__house {
    width: 100%;
    max-width: 600px;
    margin: auto;
    display: block;
  }

  .__view {
    display: block;
    margin-top: 16px;
    font-size: 14px;
    text-align: right;
  }

  .__toggler {
    float: right;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
}

main.--battery-main {
  .__not-interested-button {
    color: $brand-blue;
    cursor: pointer;
    margin-top: 24px;

    &:hover {
      text-decoration: underline;
    }

    &,
    &:focus,
    &:active {
      background-color: transparent;
      border: none;
      outline: none;
    }
  }
}

/* Recommendation Card */
.recommendation__programs-card {
  display: flex;
  align-items: center;
  border: 2px solid $brand-green;
  border-radius: 4px;
  padding: 16px;
  background: rgb(28, 226, 158);
  background: linear-gradient(180deg, rgba(28, 226, 158, 0) 0%, rgba(28, 226, 158, 0.2) 100%);

  &::before {
    content: '\e901';
    font-family: 'swell-icons';
    position: absolute;
    display: block;
    top: -16px;
    right: 0;
    width: 32px;
    height: 32px;
    color: $brand-green;
    font-size: 32px;
    background-color: $white;
    border-radius: 32px;
    overflow: hidden;
  }

  p {
    font-size: 12px;
    line-height: 16px;
    margin: 0 16px 0 0;
  }

  p.--note {
    display: none;
  }

  @include respond-min($md) {
    p.--note {
      display: inline-block;
    }
  }
}

/* Recommendation Table */

table.recommendation__table {
  width: 100%;
  border-spacing: 0;

  th,
  td {
    padding: 6px 16px;
    width: 200px;

    &:first-child {
      width: auto;
    }
  }

  span[class^='icon-'] {
    font-size: 24px;
  }

  span.icon-x {
    color: $brand-red;
  }

  span.icon-check {
    color: $brand-green;
  }

  .__col-best {
    background: rgb(28, 226, 158);
    background: linear-gradient(0deg, rgba(28, 226, 158, 0) 0%, rgba(28, 226, 158, 0.16) 100%);
  }
}

table.recommendation__table tr {
  &:nth-child(even) {
    background-color: $gray;
  }
}

table.recommendation__table th {
  text-align: center;
  font-size: 32px;

  &:first-child {
    text-align: left;
    font-size: 12px;
  }

  &.--selected {
    background-color: $brand-green;
    color: $white;
  }

  div {
    font-size: 12px;
  }

  &.--hide-phone {
    display: none;
  }

  @include respond-min($md) {
    &:first-child {
      font-size: 24px;
    }

    &.--hide-phone {
      display: table-cell;
    }
  }

  button {
    border: none;
    margin: 0;
    padding: 0;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
  }

  button::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

table.recommendation__table td {
  text-align: center;
  font-size: 12px;

  &:first-child {
    text-align: left;

    div {
      display: none;
      font-size: 12px;
    }
  }

  &.--hide-phone {
    display: none;
  }

  @include respond-min($md) {
    font-size: inherit;

    &.--hide-phone {
      display: table-cell;
    }

    &:first-child {
      div {
        display: block;
      }
    }
  }

  &.--small {
    font-size: 10px;
  }
}

main.--simple {
  .--simple-hide {
    display: none;
  }

  .--simple-hide-anim {
    opacity: 0;
  }

  .recommendation__table-container {
    transition: max-height 1s $inandoutqaud;
  }
}

main.--detail {
  .--detail-hide {
    display: none;
  }

  .--detail-range-12 {
    width: 100%;
  }

  .--detail-range-6 {
    width: 100%;

    @include respond-min($md) {
      width: 50%;
    }
  }
}

.recommendation__payment-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 64px;
  width: 100%;

  .recommendation__payments {
    display: flex;
    flex-direction: column;

    .__radio {
      justify-content: center;
      margin-top: 0;

      @include respond-min($md) {
        justify-content: start;
      }
    }

    .__contact {
      margin: 32px 16px 0;
      line-height: 2rem;

      .__contact-link {
        color: $brand-blue;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    h2 {
      margin-top: 32px;
    }

    button.__reserve {
      cursor: pointer;
      display: block;
      font-size: 1.2rem;
      margin-top: 16px;
      padding: 12px;
      border: none;
    }
  }

  .recommendation__offer {
    border: 2px solid $gray;
    border-radius: $br-4;
    padding: 12px;

    strong {
      color: $brand-blue;
    }
  }
}

.storage-recommendation__payment-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 64px;
  width: 100%;

  .storage-recommendation__payments {
    display: flex;
    flex-direction: column;

    .__radio {
      justify-content: center;
      margin-top: 0;

      @include respond-min($md) {
        justify-content: start;
      }
    }

    .__contact {
      margin: 32px 16px 0;
      line-height: 2rem;

      .__contact-link {
        color: $brand-blue;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    h2 {
      margin-top: 32px;
    }

    button {
      cursor: pointer;
      display: block;
      font-size: 1.2rem;
      margin-top: 16px;
      padding: 12px;
      border: none;
    }
  }
}

main.--thank-you {
  ul.__thank-you-list {
    list-style: none;

    li {
      position: relative;
      margin-bottom: 0;
      padding-bottom: 1.25rem;

      &:before {
        content: '';
        position: absolute;
        left: -16px;
        border-left: 2px solid #309afa;
        height: 100%;
        width: 1px;
      }

      &:first-child:before {
        top: 6px;
      }

      &:last-child:before {
        height: 6px;
      }

      &:after {
        content: url('../../../img/bullet.png');
        position: absolute;
        left: -21px;
        /*adjust manually*/
        top: 0px;
      }
    }
  }

  a {
    color: $brand-blue;

    &:hover {
      text-decoration: underline;
    }
  }
}

main.--vpp {
  p {
    margin-bottom: 16px;
  }
}

main.--simple {
  width: 100%;
  max-width: unset;
  padding: 0px;

  padding-bottom: 0 !important;
  display: flex;

  & > section {
    &:not(.single) {
      background-image: url(../../../img/lg-bg-simple.webp);
      background-position: center bottom;
      background-size: cover;
      height: unset;
      .wrapper {
        fieldset {
          padding: 24px 20px;
        }
      }
    }
    height: 100vh;
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: $grid-padding-md 78px;

    .wrapper {
      flex-grow: 1;
      flex-basis: 0;

      fieldset {
        padding: 0px;
        border: none;
      }
    }

    .wrapper.--right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 16px;
      padding-top: 28px;
    }

    @media screen and (max-width: 680px) {
      padding: $grid-padding-md 16px;
    }

    .map {
      visibility: hidden;
      width: 0;
      height: 0;
    }

    .container {
      background-color: rgba(255, 255, 255, 0.75);
      display: flex;
      flex-direction: column;
      border-radius: 16px;
      max-width: 1200px;
      margin: 0 auto;

      &.--single {
        background-color: transparent;
        max-width: 600px;
      }

      @include respond-min($lg) {
        flex-direction: row-reverse;
      }

      &.--small {
        max-width: 560px;
        margin-top: 8%;
      }

      .select {
        width: 100%;
      }

      .icons-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .icons {
          display: flex;
          align-items: center;
          font-size: 22px;
          text-align: start;

          img {
            width: 36px;
            height: 36px;
            mix-blend-mode: multiply;
          }
        }

        @media screen and (max-width: 680px) {
          display: none;
        }
      }

      .icons-wrapper > span:nth-child(3) {
        padding-right: 16px;
        padding-left: 8px;

        img {
          margin-right: 8px;
          width: 28px;
          height: 28px;
        }
      }

      .swell-ecom-tool {
        padding: 0px 1rem;
      }

      .bigTitle {
        text-align: center;
        font-size: 46px;
        font-weight: bolder;

        color: #1d1d1b;

        &.--small {
          font-size: 36px;

          @media screen and (max-width: 1200px) {
            font-size: 32px;
          }

          @media screen and (max-width: 680px) {
            font-size: 22px;
          }
        }

        @media screen and (max-width: 1200px) {
          font-size: 40px;
        }

        @media screen and (max-width: 680px) {
          font-size: 24px;
        }
      }

      .subtitle {
        margin-bottom: 24px;
        font-size: 22px;
        text-align: start;
        color: #1d1d1b;

        &.--center {
          text-align: center;
          margin-top: 28px;
        }

        @media screen and (max-width: 680px) {
          margin-bottom: 0px;
        }

        @media screen and (max-width: 1023px) {
          text-align: center;
          font-size: 18px;
        }
      }

      .__street {
        padding: 1rem 10px;
      }

      .menu-form {
        margin-top: -24px;
      }
    }
  }

  .loading {
    color: white;
    width: 36px !important;
    height: 36px !important;
  }

  .textfiled {
    background-color: white;
    box-shadow: 0px 3px 4px 0px #7c7c7cf2;
    font-family: inherit;
  }

  .select {
    text-align: left;
  }

  .textfiledlabel {
    color: #6b6b6b !important;
    font-family: inherit;
  }

  .errorhint {
    color: #a50034;
    font-family: inherit;
  }

  .btn {
    display: flex;
    width: 100%;
    padding: 12px 24px;
    text-transform: capitalize;
    border: none;
    align-items: center;
    justify-content: center;

    font-size: 22px;
    font-family: $font-main;
    padding: 0px;
    height: 40px;
    box-shadow: 4px 4px 5px 0px #7c7c7cf2 !important;
    background-color: #a50034;
    font-weight: normal !important;
    cursor: pointer;

    &:active:enabled:hover {
      filter: brightness(125%);
    }
  }

  .btn:disabled {
    cursor: default;
    background-color: #d9dadb;
  }

  .agreement {
    font-size: 12px;
    line-height: 18px;
    color: #1d1d1b;

    a {
      color: #a50034;
    }
  }
}
