/*
 * Typography
 * 
 * Anything font related.
 *
 * @since 0.0.2
 */



@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap');



/* Headlines, paragraphs, lists */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
legend,
address {
	margin: 0;

	&:first-child {
    	margin-top: 0;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
legend {
	font-family: $font-head;
	font-weight: 700;
}

h1,
legend {
	font-size: 24px;
	line-height: 28px;
	margin-top: 24px;
}

h1 {

	&.--max5,
	&.--max6,
	&.--max7 {
		margin: auto;
	}

	&.--max5 { max-width: 500px; }
	&.--max6 { max-width: 600px; }
	&.--max7 { max-width: 700px; }
}

h2 {
	font-size: 16px;
    line-height: 20px;
    margin-top: 20px;
}

h3 {
	font-size: 20px;
	line-height: 24px;
	margin-top: 12px;

	@include respond-min($md) {
    	font-size: 32px;
		line-height: 40px;
		margin-top: 16px;
    }

    @include respond-min($lg) {
    	font-size: 40px;
		line-height: 48px;
    }
}

h4 {
	font-size: 18px;
	line-height: 26px;
	margin-top: 12px;

	@include respond-min($md) {
    	font-size: 24px;
		line-height: 32px;
		margin-top: 16px;
    }

    @include respond-min($lg) {
    	font-size: 32px;
		line-height: 40px;
    }
}

h5 {
	font-size: 16px;
	line-height: 24px;
	margin-top: 12px;

	@include respond-min($md) {
    	font-size: 18px;
		line-height: 26px;
		margin-top: 16px;
    }

    @include respond-min($lg) {
    	font-size: 24px;
		line-height: 32px;
    }
}

h6 {
	font-size: 14px;
	line-height: 22px;
	margin-top: 12px;

	@include respond-min($md) {
    	font-size: 16px;
		line-height: 24px;
		margin-top: 16px;
    }
}

p,
ul,
ol,
address {
	font-size: 14px;
	line-height: 20px;
	margin-top: 12px;
	font-weight: 400;

	@include respond-min($md) {
		font-size: 16px;
		line-height: 24px;
		margin-top: 16px;
	}
}



p {

	&.--lead {
		font-size: 18px;
		line-height: 24px;

		@include respond-min($md) {
			font-size: 24px;
			line-height: 32px;
		}
	}

	&.--note {
		font-size: 12px;
		line-height: 16px;

		a {
			color: $brand-blue;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&.--center {
		text-align: center;
	}
}



address {
	font-style: normal;

	p {
		margin-top: 0;
	}
}



/* Links */
a { 
	text-decoration:none;
	outline: none;
	border: 0;
	color: $black;
	cursor: pointer;
}

a:focus {
	outline: none;
}



/* Selections */
::selection {
	background: $font-selection-bg;
	color: $font-selection-txt;
	text-shadow:none;
}

::-webkit-selection {
	background: $font-selection-bg;
	color: $font-selection-txt;
	text-shadow:none;
}

::-moz-selection {
	background: $font-selection-bg;
	color: $font-selection-txt;
	text-shadow:none;
}
